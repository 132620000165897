<template>
  <div class="col">
    <!-- adding userload here to display loading on groups hide or unhide  -->
    <server-table
      :count="count"
      :DataItems="groupsData"
      :filter="filter"
      @Refresh="Refresh"
      @reset="filter = {}"
      :title="$t('groups')"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="name"
          sortable
          :label="$t('Class_name')"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          prop="teacher_name"
          sortable
          :label="$t('teacher_name')"
          min-width="100"
        >
        </el-table-column>

        <el-table-column prop="days" :label="$t('Global.days')" min-width="100">
          <template slot-scope="scope">
            <span
              class="border-bottom border-secondary d-block text-right"
              v-for="day in scope.row.days"
              :key="day.id"
            >
              {{ day }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="course_name"
          sortable
          :label="$t('Global.course_name')"
          min-width="100"
        >
        <template slot-scope="scope">
            {{ getCourseTitle(scope.row.course_name) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="start_from"
          sortable
          :label="$t('Global.start_from')"
          min-width="100"
        >
          <template slot-scope="scope">
            {{
              scope.row.start_from
                ? toLocalDatetime(scope.row.start_from, "en")
                : "_"
            }}
          </template>
        </el-table-column>

        <el-table-column
          prop="end_at"
          sortable
          :label="$t('Global.end_at')"
          min-width="100"
        >
          <template slot-scope="scope">
            {{
              scope.row.end_at ? toLocalDatetime(scope.row.end_at, "en") : "_"
            }}
          </template>
        </el-table-column>

        <el-table-column
          prop="age_group"
          sortable
          :label="$t('Global.age_class')"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ getAgeGroup(scope.row.age_group) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="zoom_link"
          sortable
          :label="$t('Global.zoom_link')"
          min-width="90"
        >
        </el-table-column>

        <el-table-column
          prop="promo_code"
          sortable
          :label="$t('Global.promo_code')"
          min-width="90"
        >
        </el-table-column>

        <el-table-column
          prop="status"
          sortable
          :label="$t('Global.status')"
          min-width="130"
        >
          <template slot-scope="scope">
            <el-badge
              v-if="scope.row.status == 0"
              round
              size="small"
              type="danger"
              :value="$t('non-active')"
            >
            </el-badge>
            <el-badge
              v-if="scope.row.status == 1"
              round
              size="small"
              type="primary"
              :value="$t('active')"
            >
            </el-badge>
            <el-badge
              v-if="scope.row.status == 3"
              round
              size="small"
              type="warning"
              :value="$t('hidden')"
            >
            </el-badge>
          </template>
        </el-table-column>

        <el-table-column prop="" label="_" min-width="100">
          <template slot-scope="scope">
            <div class="d-flex justify-content-between">
              <!-- edit group  -->
              <i
                class="fa fa-edit text-info"
                style="cursor: pointer"
                @click="openeditdialog(scope.row)"
                v-tooltip.top-center="$t('Edit_Group')"
              ></i>
              <!-- activate & deactivate  -->
              <i
                v-if="scope.row.status == 1 || scope.row.status == 3"
                v-tooltip.top-center="$t('Delete_Group')"
                class="fa fa-trash text-danger"
                @click="deleteGroup(scope.row.id)"
              ></i>

              <!-- hide & show  -->
              <i
                v-if="scope.row.status == 3"
                v-tooltip.top-center="$t('Show_Group')"
                class="fa fa-eye-slash text-danger"
                @click="
                  changeGroupStatus({
                    group_id: scope.row.id,
                    status: { status: 1 },
                  })
                "
              ></i>
              <i
                v-if="scope.row.status != 3"
                v-tooltip.top-center="$t('Hide_Group')"
                class="fa fa-eye text-primary"
                @click="
                  changeGroupStatus({
                    group_id: scope.row.id,
                    status: { status: 3 },
                  })
                "
              ></i>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #filter>
        <el-row :gutter="10">
          <el-col :md="24"
            ><el-select
              v-model="filter.age_group"
              :placeholder="$t('Global.age_class')"
              clearable
              class="select-default mt-2 w-100"
            >
              <el-option
                v-for="(age, i) in ageList"
                :key="i"
                :label="age.title"
                :value="age.id"
                class="text-right mr-3"
              ></el-option> </el-select
          ></el-col>
        </el-row>
      </template>
    </server-table>

    <!-- Edit Group Dialog  -->
    <el-dialog
      :title="$t('Edit_Group')"
      min-width="70%"
      :visible.sync="editdialog"
      top="4vh"
      class="text-right"
    >
      <el-form
        :model="editedGroup"
        ref="editedGroupRef"
        :rules="editedGroupRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.days')" prop="days">
              <el-select
                v-model="editedGroup.days"
                clearable
                :placeholder="$t('Global.days')"
                style="width: 100%"
                multiple
              >
                <el-option
                  v-for="(item, index) in days"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Group_name')" prop="name">
              <el-input
                v-model="editedGroup.name"
                style="width: 100%"
                :placeholder="$t('Class_name')"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.age_class')" prop="age_group">
              <el-select
                v-model="editedGroup.age_group"
                clearable
                :placeholder="$t('Global.age_class')"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in ageList"
                  :key="index"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" :md="12" prop="course_id">
            <el-form-item :label="$t('courses')" prop="course_id">
              <el-select
                v-model="editedGroup.course_id"
                clearable
                :placeholder="$t('courses')"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in coursesData"
                  :key="index"
                  :label="getCourseTitle(item.title)"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- format="DD/MM/yy" -->
          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.start_from')" prop="start_from">
              <el-date-picker
                v-model="editedGroup.start_from"
                type="date"
                :placeholder="$t('Global.start_from')"
                :picker-options="datePickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <!-- format="DD/MM/yy" -->
          <el-col :span="24" :md="12">
            <el-form-item :label="$t('Global.end_at')" prop="end_at">
              <el-date-picker
                v-model="editedGroup.end_at"
                type="date"
                :placeholder="$t('Global.end_at')"
                :picker-options="datePickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item :label="$t('Global.zoom_link')">
              <el-input
                v-model="editedGroup.zoom_link"
                style="width: 100%"
                :placeholder="$t('Global.zoom_link')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer w-50 flex-column flex-lg-row d-md-flex my-3 mr-auto"
        dir="ltr"
      >
        <el-button
          class="text-center ml-lg-2 mx-auto d-block w-100 my-1"
          @click="editdialog = false"
          >{{ $t("cancel") }}
        </el-button>
        <el-button
          class="text-center px-4 px-lg-3 ml-lg-2 mx-auto d-block text-capitalize w-100 my-1"
          type="primary"
          @click="edit"
          >{{ $t("save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import Swal from "sweetalert2";
import Button from "../../components/Button.vue";

export default {
  components: { ServerTable, Button },
  data() {
    return {
      // edit data for edit dialog
      editedGroup: {},
      editdialog: false,
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      days: [
        this.$t("sunday"),
        this.$t("monday"),
        this.$t("tuesday"),
        this.$t("wednesday"),
        this.$t("thursday"),
        this.$t("friday"),
        this.$t("saturday"),
      ],
      ageList: [
        { id: 1, title: this.$t("less_than_6") },
        { id: 2, title: this.$t("from_6_to_8") },
        { id: 3, title: this.$t("from_9_to_12") },
        { id: 4, title: this.$t("more_than_13") },
        { id: 5, title: this.$t("all_ages") },
      ],
      editedGroupRules: {
        name: [
          {
            required: true,
            message: this.$t("course_name_required"),
            trigger: "blur",
          },
        ],
        days: [
          {
            required: true,
            message: this.$t("days_required"),
            trigger: "change",
          },
        ],
        course_id: [
          {
            required: true,
            message: "يجب ادخال الدورة",
            trigger: "change",
          },
        ],
        age_group: [
          {
            required: true,
            message: this.$t("course_required"),
            trigger: "change",
          },
        ],
        start_from: [
          {
            required: true,
            message: this.$t("start_date_required"),
            trigger: "change",
          },
        ],
        end_at: [
          {
            required: true,
            message: this.$t("end_date_required"),
            trigger: "change",
          },
        ],
      },

      // filter data
      filter: {},
    };
  },
  computed: {
    groupsData() {
      return this.$store.getters["groups/authGroups"];
    },
    coursesData() {
      return this.$store.getters["groups/getCoursesDataInGroups"];
    },
    count() {
      return this.$store.getters["groups/count"];
    },
  },
  methods: {
    Refresh(query) {
      this.$store.dispatch("groups/authGroups", { query: query });
    },

    getCourseTitle(title) {
      return this.getLocalizedText(title);
    },
    getCourseDescription(description) {
      return this.getLocalizedText(description);
    },
    getLessonTitle(title) {
      return this.getLocalizedText(title);
    },
    getLocalizedText(jsonString) {
      const locale = this.$i18n.locale;
      if (jsonString) {
        try {
          const parsedData = JSON.parse(jsonString);
          console.log("Parsed Data:", parsedData);
          return parsedData[locale] || parsedData["en"]; // Fallback to English if the locale is not found
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return jsonString; // Return the original string if it's not valid JSON
        }
      } else {
        return "";
      }
    },

    getAgeGroup(id) {
      switch (id) {
        case 1:
          return this.$t("less_than_6");
        case 2:
          return this.$t("from_6_to_8");
        case 3:
          return this.$t("from_9_to_12");
        case 4:
          return this.$t("more_than_13");
        default:
          return this.$t("all_ages");
      }
    },

    openeditdialog(item) {
      console.log(item);
      // copy data to prevent save data temporarly if user close dialog without saving
      this.editedGroup = { ...item };
      this.editdialog = true;
    },
    edit() {
      // use destruction to extract a specific data to send with request
      // all mentioned will not be sent
      let {
        course_image,
        course_name,
        promo_code,
        status,
        teacher_id,
        teacher_image,
        teacher_name,
        ...data
      } = this.editedGroup;
      console.log(data);
      this.$refs["editedGroupRef"].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("groups/updateGroup", {
              data,
              id: this.editedGroup.id,
            })
            .then((_) => {
              this.Refresh();
              this.editdialog = false;
            });
        } else {
          console.log("erorr");
          return false;
        }
      });
    },

    deleteGroup(id) {
      Swal.fire({
        title: this.$t("confirm_group_delete"),
        text: this.$t("ask_for_delete_group"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("groups/deleteGroup", id).then((_) => {
            this.Refresh({});
            Swal.fire({
              title: this.$t("deleted"),
              text: this.$t("group_deleted"),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },

    changeGroupStatus(query) {
      Swal.fire({
        title:
          query.status.status == 3
            ? this.$t("confirm_hide_group")
            : this.$t("confirm_show_group"),
        text:
          query.status.status == 3
            ? this.$t("ask_hide_group")
            : this.$t("ask_show_group"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store.dispatch("user/changeGroupStatus", query).then((_) => {
            this.Refresh();
            Swal.fire({
              title: query.status.status
                ? this.$t("is_hidden")
                : this.$t("is_hidden"),
              text: quey.status.status
                ? this.$t("group_data_shown")
                : this.$t("group_data_shown"),
              icon: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}

.fa {
  font-size: 1rem;
}
::v-deep .cell {
  text-align: right;
  word-break: break-word;
}

::v-deep .el-form--label-top .el-form-item__label {
  position: relative;
}

::v-deep .el-form-item__label:before {
  position: absolute;
  left: -12px;
}

/* badge style  */

::v-deep sup {
  top: 0;
}

::v-deep sup.el-badge__content {
  height: unset;
  padding: 5px 20px;
  font-size: 0.9rem;
}

::v-deep .el-dialog__headerbtn {
  left: 20px;
  right: unset;
}

::v-deep .el-form-item__error {
  left: unset;
  right: 0;
  font-size: 0.9rem;
}

/* form select inputs  */
.el-select-dropdown__item {
  padding: 5px 40px;
  text-align: right;
}
</style>

<style lang="scss">
.el-select .el-input:hover .el-input__icon,
.el-select .el-input:hover input {
  color: #777;
}
</style>
